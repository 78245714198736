import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const ThankYouScreen = (props) => {
  setTimeout(function () {
    window.location.replace("/");
  }, 5000);

  return (
    <Container className="py-5 thankyou-container">
      <h1 className="text-center red">Děkujeme Vám!</h1>
      <p className="text-center pt-3">
        Za 5 sekund budete automaticky přesměrováni.
      </p>
      <p className="text-center">
        Pokud se tak nestalo klikněte <Link to="/">zde</Link>.
      </p>
    </Container>
  );
};

export default ThankYouScreen;
