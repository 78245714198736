import React from 'react';
import './ContactScreen.css';
import { Container, Row, Col } from 'react-bootstrap';
import {
  FaEnvelope,
  FaInstagram,
  FaLocationArrow,
  FaPhone,
} from 'react-icons/fa';

const ContactScreen = (props) => {
  return (
    <Container className="contact-container">
      <h1 className="text-center red py-5">
        Kontaktujte nás pro tuning Vašeho vozidla!
      </h1>
      <Row>
        <Col xs={12} md={6}>
          <h3>Kontaktní údaje</h3>
          <h2 className="red">Karel Sobek s.r.o</h2>
          <div className="d-flex mt-3">
            <FaEnvelope className="me-3" size={35} color="red" />
            <h3>
              <a className="black" href="mailto:ksobek@seznam.cz">
                ksobek@seznam.cz
              </a>
            </h3>
          </div>
          <div className="d-flex mt-3">
            <FaPhone className="me-3" size={35} color="red" />
            <h3>
              <a className="black" href="tel:+420608111335">
                +420 608 111 335
              </a>
            </h3>
          </div>
          <div className="d-flex mt-3">
            <FaLocationArrow className="me-3" size={35} color="red" />
            <h3>Frýdek-Místek, Příborská 2241</h3>
          </div>
          <div className="mt-3">
            <a href="https://www.instagram.com/fmtunning/">
              <FaInstagram size={35} color="red" />
            </a>
          </div>
        </Col>
        <Col className="my-auto text-center" xs={12} md={6}>
          <img src="./img/logo.svg" alt="FM-Tuning logo" className="w-50" />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactScreen;
