import React from "react";
import "./HomeScreen.css";
import { Container, Row, Col } from "react-bootstrap";

const HomeScreen = (props) => {
  return (
    <>
      <Container
        className="hp-main-container d-flex align-items-center"
        fluid
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img//hp-main-img.jpg"
          })`,
        }}
      >
        <Container>
          <h1 className="white red bold hp-main-text" data-aos="fade-up">
            FM-Tuning
          </h1>
          <h3 className="bold white hp-main-undertext" data-aos="fade-up">
            Zvýšení výkonu + snížení spotřeby
          </h3>
        </Container>
      </Container>
      <Container className="py-5">
        <Row>
          <Col xs={12} md={6}>
            <div className="py-5">
              <h5 className="red">CHIPTUNING</h5>
              <p>
                Naše firma se zabýva optimalizováním výkonu vozidel s měřením
                výkonu vozidel na válcové motorové brzdě 2WD. Tato úprava výkonu
                se nazývá „chiptuning“.
                <br />
                Co je to chiptuning?
                <br />
                Chiptuning je úprava řídicí jednotky vozidla tak, aby došlo ke
                zvýšeni výkonu motoru a sníženi průměrné spotřeby při zachování
                emisních parametrů a životnosti motoru. Všechny moderní motory
                dnes využívají k řízeni chodu motoru řídicí jednotku. Pokud se
                optimalizují parametry uložené v těchto řídicích jednotkách
                správným způsobem, dojde k okamžité úspoře paliva a zároveň k
                nárustu výkonu a točivého momentu.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <img
              className="w-100"
              src="./img/popis-col-second.png"
              alt="FM-Tuning chip tuning"
            ></img>
          </Col>
        </Row>
        <Row className="py-5">
          <Col xs={12} md={6}>
            <img
              className="w-100"
              src="./img/popis-col-first.png"
              alt="FM-Tuning chip tuning"
            ></img>
          </Col>
          <Col xs={12} md={6}>
            <div className="py-5">
              <h5 className="red">NABÍZÍME SOFTWAROVÉ I HARDWAROVÉ ÚPRAVY</h5>
              <p>
                ► oprava teplých startu u dieselových motoru
                <br />► úprava filtru pevných čistíc (DPF/FAP) – nehomologováno
                !
                <br />► úprava recirkulace výfukových plynov (EGR) –
                nehomologováno !
                <br />► úprava Ad Blue – nehomologováno !
                <br />► úprava sport ukazatele
                <br />► úprava filtru GPF/OPF
                <br />► odstraněni klapky výfuku
                <br />► odstraněni O2 sensoru
                <br />► odstraněni max. rychlosti
                <br />► úprava start stop systému
                <br />► úprava Popcorn,Burbles – nehomologováno !
                <br />► odstranění vířivé klapky – nehomologováno !
                <br />► odstranění DTC – (chybové kódy) – nehomologováno !
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="py-5">
        <Row className="text-center">
          <Col xs={12} md={4}>
            <h5 className="red">BEZPEČNÉ A DYNAMICKÉ PŘEDJÍŽDĚNÍ</h5>
            <p>
              Naše firma Vám nabízí kombinaci prostřednictvím optimalizaci
              výkonu, kde se zvýši výkon motoru a sníží spotřebu.
            </p>
          </Col>
          <Col xs={12} md={4}>
            <h5 className="red">SNÍŽENÍ SPOTŘEBY</h5>
            <p>
              Muže se to zdát nepochopitelné, že zásahem do řídící jednotky se
              zvýší výkon motoru a zároveň klesne spotřeba pohonných hmot.
            </p>
          </Col>
          <Col xs={12} md={4}>
            <h5 className="red">ZVÝŠENÍ VÝKONU BEZ RIZIKA</h5>
            <p>
              Díky dlouholeté praxe „čipujeme“ odborně a bez rizika poškození
              motoru.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeScreen;
