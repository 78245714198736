import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";

const Header = () => {
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="./img/logo.svg"
              alt="FM-Tuning logo"
              className="w-50"
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="nav-toggler" />
          <Navbar.Collapse id="nav-toggler">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/">
                Úvod
              </Nav.Link>
              <Nav.Link as={Link} to="/sluzby">
                Služby
              </Nav.Link>
              <Nav.Link as={Link} to="/kontakt">
                Kontakt
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
