import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram } from 'react-icons/fa6';

const Footer = () => {
  const year = new Date();

  return (
    <Container fluid style={{ background: '#000000' }} className="mt-5">
      <Container className="py-5">
        <Row>
          <Col xs={12} md={4}>
            <h6 className="red">&copy;{year.getFullYear()} FM-tuning</h6>
          </Col>
          <Col xs={12} md={4} className="margin-top-mobile">
            <h6 className="red">Rychlé odkazy</h6>
            <Link className="white" to="/">
              Úvod
            </Link>
            <br />
            <Link className="white" to="/sluzby">
              Sluzby
            </Link>
            <br />
            <Link className="white" to="/kontakt">
              Kontakt
            </Link>
          </Col>
          <Col xs={12} md={4} className="margin-top-mobile">
            <h6 className="red">Kontakt</h6>
            <a className="white" href="tel:+420608111335">
              <h6>+420 608 111 335</h6>
            </a>
            <a href="https://www.instagram.com/fmtunning/">
              <FaInstagram size={25} color="white" />
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
