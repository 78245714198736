import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import ServicesScreen from './screens/ServicesScreen';
import ErrorScreen from './screens/ErrorScreen';
import { Route, Routes } from 'react-router-dom';
import ContactScreen from './screens/ContactScreen';
import ThankYouScreen from './screens/ThankYouScreen';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/sluzby" element={<ServicesScreen />} />
          <Route path="/kontakt" element={<ContactScreen />} />
          <Route path="/dekujeme" element={<ThankYouScreen />} />
          <Route path="*" element={<ErrorScreen />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  );
}

export default App;
