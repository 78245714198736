import React from "react"
import { Container, Row, Col } from "react-bootstrap";

const ServicesScreen = (props) => {
  return (
    <>
      <Container>
        <h1 className="text-center py-5 red"> Co od nás můžete čekat</h1>
        <Row className="py-5">
        <Col xs={12} md={6}>
            <div className="py-5">
              <h3 className="red">Diagnostika před upravení jednotky</h3>
              <p>Kontrola se provádí diagnostikou, která je určena pro typ vozidla. Pokud diagnostika zjisti problém v nějak dílu nemusí Chip tuning fungovat na 100 %, ale náš tým dokáže chybu/vadnou součástku v servisu opravit.</p>
            </div>
            <div className="py-5"> 
              <h3 className="red">Individuální tuning pro každý typ vozidla</h3>
              <p>Každý Chip tuning je individuálně vyvíjen pro každý typ vozidla/jednotky zvlášť, aby se vozidlu zvětšil výkon a snížila spotřeba. Chip tuning je vyvíjen v profesionálních programech určené pro úpravy jednotky.</p>
            </div>
          </Col>
          <Col>
            <img className="w-100" src="./img/sluzba-col-first.png" alt="BMW M4"></img>
          </Col>
        </Row> 
        <Row>
          <Col>
            <img className="w-100" src="./img/sluzba-col-second.png" alt="BMW M4"></img>
          </Col>
          <Col xs={12} md={6}>
            <div className="py-5">
              <h3 className="red">Diagnostika po úpravě</h3>
              <p>Pokud nesviti žádná kontrolka není potřeba dělat diagnostiku, ale v opačném případě se musí udělat. Problém bude řešen kontrolou software a doupraveni software, aby to vašemu vozidlu nevadilo a kontrolka nesvětila.</p>
            </div>
            <div className="py-3">
              <h3 className="red">Záloha na Chip tuning</h3>
              <p>Vztahuje se pouze na software, který jsme vám dodali do vozidla/jednotky. Poznáme cizí zásah do vozidla/jednotky tím pádem to nebude náš problém co se tyče nefunkčního softwaru.</p>
            </div> 
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ServicesScreen;
